.docs-example-row .row > .col span,
.docs-example-row .row > [class^="col-"] span {
  display: block;
  padding: 0.75rem;
  color: #393f49;
  background-color: #fff;
  -webkit-box-shadow: rgba(0, 0, 0, 0.1) 0 0 0 1px, rgba(0, 0, 0, 0.1) 0 4px 16px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 16px rgba(0, 0, 0, 0.1);
  font-size: 0.875rem;
  border-radius: 0.25rem;
  margin: 1rem 0;
}
.docs-example-row .row.no-gutters > .col span,
.docs-example-row .row.no-gutters > [class^="col-"] span {
  border-radius: 0;
}
.docs-example-row .row + .row {
  margin-top: 1rem;
}
.docs-example-row .flex-items-bottom,
.docs-example-row .flex-items-middle,
.docs-example-row .flex-items-top {
  min-height: 6rem;
  background-color: rgba(255, 0, 0, 0.1);
}
.docs-example-row-flex-cols .row {
  min-height: 10rem;
  background-color: rgba(255, 0, 0, 0.1);
}
.docs-highlight {
  background-color: rgba(0, 82, 204, 0.15);
  border: 1px solid rgba(0, 82, 204, 0.15);
}
.docs-example + .docs-clipboard {
  margin-top: 1rem;
}
.example-container {
  width: 800px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.example-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.example-content-main {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
@media (min-width: 576px) {
  .example-content-main {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media (min-width: 992px) {
  .example-content-main {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.6666666667%;
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
}
.example-content-secondary {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
@media (min-width: 576px) {
  .example-content-secondary {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media (min-width: 992px) {
  .example-content-secondary {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
}
.docs-example-container {
  min-width: 16rem;
  max-width: 25rem;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 1.5rem;
}
.docs-example-container-header {
  height: 3rem;
  margin-bottom: 0.5rem;
  background-color: #fff;
  border-radius: 0.25rem;
}
.docs-example-container-sidebar {
  float: right;
  width: 4rem;
  height: 8rem;
  background-color: #a6ccff;
  border-radius: 0.25rem;
}
.docs-example-container-body {
  height: 8rem;
  margin-right: 4.5rem;
  background-color: #4d94ff;
  border-radius: 0.25rem;
}
.docs-example-container-fluid {
  max-width: none;
}
.docs-example .tab-example-result {
  position: relative;
}
.docs-example .tab-example-result:after {
  display: block;
  clear: both;
  content: "";
}
@media (min-width: 576px) {
  .docs-example .tab-example-result {
    margin-right: 0;
    margin-left: 0;
  }
}
.docs-example .tab-example-result > .toast {
  display: block;
  opacity: 1;
}
.docs-example .tab-example-result + .clipboard + .highlight,
.docs-example .tab-example-result + .highlight {
  margin-top: 0;
}
.docs-example .tab-example-result + p {
  margin-top: 2rem;
}
.docs-example .tab-example-result .pos-f-t {
  position: relative;
  margin: -1rem;
}
@media (min-width: 576px) {
  .docs-example .tab-example-result .pos-f-t {
    margin: -1.5rem;
  }
}
.docs-example .tab-example-result .custom-file-input:lang(es) ~ .custom-file-label:after {
  content: "Elegir";
}
.docs-example .tab-example-result > .form-control + .form-control {
  margin-top: 0.5rem;
}
.docs-example .tab-example-result > .card:last-child,
.docs-example .tab-example-result > .progress {
  margin-bottom: 0;
}
.docs-example .tab-example-result > .alert + .alert,
.docs-example .tab-example-result > .badge:not(.tag),
.docs-example .tab-example-result > .btn,
.docs-example .tab-example-result > .nav + .nav,
.docs-example .tab-example-result > .navbar + .navbar,
.docs-example .tab-example-result > .progress + .btn,
.docs-example .tab-example-result > .progress + .progress,
.docs-example .tab-example-result > nav > .breadcrumb {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}
.docs-example .tab-example-result > .btn-group {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.docs-example .tab-example-result > .alert,
.docs-example .tab-example-result > .btn-group .btn {
  margin: 0;
}
.docs-example .tab-example-result > .alert + .alert {
  margin-top: 1.25rem;
}
.docs-example .tab-example-result > .alert .btn,
.docs-example .tab-example-result > .input-group .btn {
  margin: 0 !important;
}
.docs-example .tab-example-result > .badge {
  margin-right: 0.5rem;
}
.docs-example .tab-example-result > .btn .badge {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  margin-right: 0 !important;
}
.docs-example .tab-example-result > .dropdown-menu:first-child {
  position: static;
  display: block;
}
.docs-example .tab-example-result > .form-group:last-child {
  margin-bottom: 0;
}
.docs-example .tab-example-result > .close {
  float: none;
}
.docs-example-type .table .type-info {
  color: #999;
  vertical-align: middle;
}
.docs-example-type .table td {
  padding: 1rem 0;
  border-color: #eee;
}
.docs-example-type .table tr:first-child td {
  border-top: 0;
}
.docs-example-type h1,
.docs-example-type h2,
.docs-example-type h3,
.docs-example-type h4,
.docs-example-type h5,
.docs-example-type h6 {
  margin-top: 0;
  margin-bottom: 0;
}
.docs-example-bg-classes p {
  padding: 1rem;
}
.docs-example > img + img {
  margin-left: 0.5rem;
}
.docs-example > .btn-group {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}
.docs-example-control-sizing input[type="text"] + input[type="text"],
.docs-example-control-sizing select,
.docs-example > .btn-toolbar + .btn-toolbar {
  margin-top: 0.5rem;
}
.docs-example-form .input-group {
  margin-bottom: 0.5rem;
}
.docs-example > textarea.form-control {
  resize: vertical;
}
.docs-example > .list-group {
  max-width: 400px;
}
.docs-example .fixed-top,
.docs-example .sticky-top {
  position: static;
  margin: -1rem -1rem 1rem;
}
.docs-example .fixed-bottom {
  position: static;
  margin: 1rem -1rem -1rem;
}
@media (min-width: 576px) {
  .docs-example .fixed-top,
  .docs-example .sticky-top {
    margin: -1.5rem -1.5rem 1rem;
  }
  .docs-example .fixed-bottom {
    margin: 1rem -1.5rem -1.5rem;
  }
}
.docs-example .pagination {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.modal {
  z-index: 1072;
}
.modal .popover,
.modal .tooltip {
  z-index: 1073;
}
.modal-backdrop {
  z-index: 1071;
}
.docs-example-modal {
  background-color: #fafafa;
}
.docs-example-modal .modal {
  position: relative;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  z-index: 1;
  display: block;
}
.docs-example-modal .modal-dialog {
  left: auto;
  margin-right: auto;
  margin-left: auto;
}
.docs-example-tabs .nav-tabs {
  margin-bottom: 1rem;
}
.docs-example-popover-static {
  padding-bottom: 1.5rem;
  background-color: #f9f9f9;
}
.docs-example-popover-static .popover {
  position: relative;
  display: block;
  float: left;
  width: 260px;
  margin: 1.25rem;
}
.tooltip-demo a {
  white-space: nowrap;
}
.docs-example-tooltip-static .tooltip {
  position: relative;
  display: inline-block;
  margin: 10px 20px;
  opacity: 1;
}
.scrollspy-example {
  position: relative;
  height: 200px;
  margin-top: 0.5rem;
  overflow: auto;
}
.scrollspy-example-2 {
  position: relative;
  height: 350px;
  overflow: auto;
}
.docs-example-border-utils [class^="border"] {
  display: inline-block;
  width: 5rem;
  height: 5rem;
  margin: 0.25rem;
  background-color: #f5f5f5;
}
.docs-example-border-utils-0 [class^="border"] {
  border: 1px solid #eff2f7;
}
.highlight {
  padding: 0;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
@media (min-width: 576px) {
  .highlight {
    padding: 0;
  }
}
.docs-content .highlight {
  margin-right: -15px;
  margin-left: -15px;
}
@media (min-width: 576px) {
  .docs-content .highlight {
    margin-right: 0;
    margin-left: 0;
  }
}
.docs-example {
  position: relative;
}
.docs-example > .nav-example {
  margin: 0.5rem 0;
}
.docs-example > .nav-example .nav-item:not(:last-child) {
  margin-right: 0.5rem;
}
.docs-example > .nav-example .nav-link {
  padding: 0.357rem 0.75rem;
  font-size: 0.75rem;
  background-color: #fff;
  color: #273444;
  border: 1px solid #eff2f7;
}
.docs-example > .nav-example .nav-link:hover {
  border-color: #e5eaf2;
  background-color: #f7f7f7;
}
.docs-example > .nav-example .nav-link.active,
.docs-example > .nav-example .nav-link:active {
  border-color: #6e00ff;
  color: #6e00ff;
  background-color: #fff;
}
.docs-example .tab-example-html {
  position: relative;
  margin-bottom: 3rem;
}
.docs-example .tab-example-result {
  padding: 1.25rem;
  margin-bottom: 3rem;
  border: 1px solid #eff2f7;
  border-radius: 0.25rem;
}
.docs-example .tab-example-html .highlight {
  margin-bottom: 0;
}
.docs-example .btn-clipboard {
  position: absolute;
  top: 1px;
  right: 1px;
  z-index: 10;
  display: block;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  font-weight: 700;
  cursor: pointer;
  background-color: #fff;
  color: #273444;
  border: 0;
  border-left: 1px solid #eff2f7;
  border-bottom: 1px solid #eff2f7;
  border-radius: 0 0 0 4px;
}
.docs-example .btn-clipboard:hover {
  color: #6e00ff;
  background-color: #f7f7f7;
}
.docs-example .btn-clipboard.active,
.docs-example .btn-clipboard:active {
  color: #6e00ff;
  background-color: #fff;
}
.docs-clipboard,
.docs-page-title {
  position: relative;
}
.docs-page-title {
  margin-bottom: 1.5rem;
}
.docs-page-title .heading {
  margin-bottom: 1rem;
  padding: 0;
  font-size: 1.5rem;
  font-weight: 300;
}
.docs .navbar-nav .nav-link {
  font-family: inherit;
  font-weight: 400;
}
.docs .navbar-nav .nav-link.active {
  position: relative;
}
.docs .navbar-nav .nav-link.active:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 1.5rem;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #6e00ff;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.docs-content {
  margin-top: 4rem;
  margin-bottom: 1.5rem;
  padding: 3rem;
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}
@media (max-width: 767.98px) {
  .docs-content {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
.docs-content > h2[id],
.docs-content > h3[id],
.docs-content > h4[id] {
  pointer-events: none;
}
.docs-content > h2[id] > a,
.docs-content > h2[id] > div,
.docs-content > h3[id] > a,
.docs-content > h3[id] > div,
.docs-content > h4[id] > a,
.docs-content > h4[id] > div {
  pointer-events: auto;
}
.docs-content > h2[id]:before,
.docs-content > h3[id]:before,
.docs-content > h4[id]:before {
  display: block;
  height: 6rem;
  margin-top: -6rem;
  visibility: hidden;
  content: "";
}
.docs-content > table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
}
@media (max-width: 991.98px) {
  .docs-content > table {
    display: block;
    overflow-x: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .docs-content > table.table-bordered {
    border: 0;
  }
}
.docs-content > table > tbody > tr > td,
.docs-content > table > tbody > tr > th,
.docs-content > table > tfoot > tr > td,
.docs-content > table > tfoot > tr > th,
.docs-content > table > thead > tr > td,
.docs-content > table > thead > tr > th {
  padding: 1rem;
  vertical-align: top;
  border: 1px solid #eff2f7;
}
.docs-content > table > tbody > tr > td > p:last-child,
.docs-content > table > tbody > tr > th > p:last-child,
.docs-content > table > tfoot > tr > td > p:last-child,
.docs-content > table > tfoot > tr > th > p:last-child,
.docs-content > table > thead > tr > td > p:last-child,
.docs-content > table > thead > tr > th > p:last-child {
  margin-bottom: 0;
}
.docs-content > table td:first-child > code {
  white-space: nowrap;
}
.docs-content h2:not(:first-child) {
  margin-top: 3rem;
  font-size: 1.5rem;
  font-weight: 500;
}
.docs-content h3 {
  margin-top: 2.5rem;
  font-size: 1.25rem;
  font-weight: 500;
}
.docs-content h4 {
  margin-bottom: 1rem;
  font-size: 0.75rem;
  text-transform: uppercase;
  font-weight: 500;
}
.docs-content > ol li,
.docs-content > ul li {
  margin-bottom: 0.25rem;
}
.docs-page-title {
  margin-bottom: 1rem;
}
.docs-title {
  margin-top: 0.875rem;
  margin-bottom: 0.5rem;
  font-weight: 300;
}
@media (min-width: 576px) {
  .docs-title {
    font-size: 1.5rem;
    font-weight: 600;
  }
}
.docs-lead {
  color: #3b454e;
  font-weight: 500;
}
@media (min-width: 576px) {
  .docs-lead {
    margin-bottom: 1rem;
  }
}
.docs-text-purple {
  color: #0052cc;
}
.docs-text-purple-bright {
  color: #1a76ff;
}
.docs-tabs-example .nav-link i {
  margin-right: 5px;
}
.typeface-entry {
  position: relative;
  display: block;
  padding: 2rem;
  background: #fff;
  border: 1px solid #eff2f7;
  border-radius: 0.375rem;
}
.typeface-badge {
  position: absolute;
  top: 10px;
  right: 10px;
}
.color-palette .color-group .color-item .color {
  position: relative;
  display: block;
  padding: 1rem;
  min-height: 50px;
}
.color-palette .color-group .color-item:first-child .color {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.color-palette .color-group .color-item:last-child .color {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.color-palette .color-group .color-item:only-child .color {
  border-radius: 0.25rem;
}
.color-palette .color-code,
.color-palette .color-name {
  display: block;
}
.color-palette .color-code {
  text-transform: uppercase;
}
.color-palette .color-item-light .color-name {
  color: #3c4858;
}
.color-palette .color-item-light .color-code {
  color: rgba(60, 72, 88, 0.7);
}
.color-palette .color-item-dark .color-name {
  color: #fff;
}
.color-palette .color-item-dark .color-code {
  color: hsla(0, 0%, 100%, 0.7);
}
.color-switch a {
  width: 1rem;
  height: 1rem;
  margin-right: 0.25rem;
  border-radius: 50%;
}
.header {
  position: fixed;
  z-index: 1050;
}
.header:after {
  display: block;
  content: "";
  clear: both;
}
.header .body-backdrop {
  position: absolute;
}
.docs .sidenav {
  height: calc(100% - 84px);
  top: 84px;
}
.docs .sidenav .navbar-nav-docs > .nav-item {
  padding: 0 0.5rem;
}
.docs .sidenav .navbar-nav-docs > .nav-item > .nav-link {
  padding-top: 0.875rem !important;
  padding-bottom: 0.875rem !important;
}
.docs .sidenav .navbar-nav-docs > .nav-item .nav .nav-item .nav-link {
  padding-left: 2.5rem;
}
.section-nav {
  padding: 0;
}
.toc-entry {
  display: block;
}
.toc-entry ul {
  padding-left: 1rem;
}
.toc-entry a {
  display: block;
  padding: 0.125rem 0;
  font-size: 0.875rem;
  color: #8492a6;
}
.toc-entry a:hover {
  color: #c0ccda;
  text-decoration: none;
}

.border-top-dashed {
  border-top: 1px dashed #eff2f7;
}

.sidebar-height {
  max-height: 90.9vh;
}